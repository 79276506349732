import * as React from 'react';
import { Button, StyleSheet } from 'react-native';
import { Avatar, Icon } from 'react-native-elements';
import { NavigationContainer } from '@react-navigation/native';

import EditScreenInfo from '../components/EditScreenInfo';
import { Text, View } from '../components/Themed';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../types';

type HomeScreenNavigationProp = StackNavigationProp<
  RootStackParamList,
  'Root'
>;

type Props = {
  navigation: HomeScreenNavigationProp;
};
export default function TabHomeScreen( {navigation}: {navigation: any}) {
  return (
    <View style={styles.container}>
      <Avatar
        size="large"
        rounded
        source={{
          uri:
          'https://lh3.googleusercontent.com/ogw/ADea4I5orDK78vhVfNTw2MtTiT591KEou9vLBQKKJhIPlg=s83-c-mo',
        }}
        onPress={() => console.log("Works!")}
        //activeOpacity={0.7}
        //containerStyle={{flex: 1}}
      />

      <Text style={styles.title}>Hello Binh, hôm nay bạn cảm thấy sao?</Text>
      <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />
      <Icon  name='rowing' />
      <Button
        title="Triệu Chứng"
        onPress={() => navigation.navigate('Triệu Chứng')}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    alignItems: 'flex-start'
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});

/**
 * Learn more about createBottomTabNavigator:
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */

import { Ionicons } from '@expo/vector-icons';
import { Icon } from 'react-native-elements';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';

import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import TabHomeScreen from '../screens/TabHomeScreen';
import TabCareScreen from '../screens/TabCareScreen';
import TabSymptomScreen from '../screens/TabSymptomScreen';
import TabCounsellingScreen from '../screens/TabCounsellingScreen';
import TabMedicationScreen from '../screens/TabMedicationScreen';
import { BottomTabParamList, TabHomeParamList, TabCareParamList, TabSymptomParamList, TabCounsellingParamList, TabMedicationParamList } from '../types';


const BottomTab = createBottomTabNavigator<BottomTabParamList>();
//const BottomTab = createBottomTabNavigator();

export default function BottomTabNavigator() {
  const colorScheme = useColorScheme();

  return (
    <BottomTab.Navigator
      initialRouteName="Home"
      tabBarOptions={{ activeTintColor: Colors[colorScheme].tint }}>
      <BottomTab.Screen
        name="Symptom"
        component={TabSymptomNavigator}
        options={{
          tabBarIcon: ({ color }) => <Icon name="diagnoses" type="font-awesome-5" color={color} />,
          title: 'Triệu chứng'
        }}
      />
      <BottomTab.Screen
        name="Counselling"
        component={TabCounsellingNavigator}
        options={{
          tabBarIcon: ({ color }) => <Icon name="user-md" type="font-awesome-5" color={color} />,
          title: 'Tham vấn',
        }}
      />
      <BottomTab.Screen
        name="Home"
        component={TabHomeNavigator}
        options={{
          tabBarIcon: ({ color }) => <Icon name="clinic-medical" type="font-awesome-5" color={color} />,
          title: 'Hôm nay'
        }}
      />
      
      <BottomTab.Screen
        name="Medication"
        component={TabMedicationNavigator}
        options={{
          tabBarIcon: ({ color }) => <Icon name="briefcase-medical" type="font-awesome-5" color={color} />,
          title: 'Tủ thuốc'
        }}
      />

      <BottomTab.Screen
        name="Care"
        component={TabCareNavigator}
        options={{
          tabBarIcon: ({ color }) => <TabBarIcon name="fitness-outline" color={color} />,
          title: 'Sức khỏe'
        }}
      />
    </BottomTab.Navigator>
  );
}

// You can explore the built-in icon families and icons on the web at:
// https://icons.expo.fyi/
function TabBarIcon(props: { name: React.ComponentProps<typeof Ionicons>['name']; color: string }) {
  return <Ionicons size={30} style={{ marginBottom: -3 }} {...props} />;
}

// Each tab has its own navigation stack, you can read more about this pattern here:
// https://reactnavigation.org/docs/tab-based-navigation#a-stack-navigator-for-each-tab
const TabHomeStack = createStackNavigator<TabHomeParamList>();

function TabHomeNavigator() {
  return (
    <TabHomeStack.Navigator>
      <TabHomeStack.Screen
        name="TabHomeScreen"
        component={TabHomeScreen}
        options={{ headerTitle: 'Sức khỏe hôm nay' }}
      />
    </TabHomeStack.Navigator>
  );
}

const TabCareStack = createStackNavigator<TabCareParamList>();

function TabCareNavigator() {
  return (
    <TabCareStack.Navigator>
      <TabCareStack.Screen
        name="TabCareScreen"
        component={TabCareScreen}
        options={{ headerTitle: 'Sức khỏe' }}
      />
    </TabCareStack.Navigator>
  );
}

const TabSymptomStack = createStackNavigator<TabSymptomParamList>();

function TabSymptomNavigator() {
  return (
    <TabSymptomStack.Navigator>
      <TabSymptomStack.Screen
        name="TabSymptomScreen"
        component={TabSymptomScreen}
        options={{ headerTitle: 'Triệu chứng' }}
      />
    </TabSymptomStack.Navigator>
  );
}

const TabCounsellingStack = createStackNavigator<TabCounsellingParamList>();

function TabCounsellingNavigator() {
  return (
    <TabCounsellingStack.Navigator>
      <TabCounsellingStack.Screen
        name="TabCounsellingScreen"
        component={TabCounsellingScreen}
        options={{ headerTitle: 'Tham vấn' }}
      />
    </TabCounsellingStack.Navigator>
  );
}

const TabMedicationStack = createStackNavigator<TabMedicationParamList>();

function TabMedicationNavigator() {
  return (
    <TabMedicationStack.Navigator>
      <TabMedicationStack.Screen
        name="TabMedicationScreen"
        component={TabMedicationScreen}
        options={{ headerTitle: 'Medication' }}
      />
    </TabMedicationStack.Navigator>
  );
}


